export class PaymentMethods {
  constructor({ http }) {
    this.http = http;
  }

  async list({ idAddress }) {
    let url = `payment/methods?IdAddress=${idAddress}`;

    const response = await this.http.API_AUTH.get(url);

    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }
}
